/* You can add global styles to this file, and also import other style files */

@import 'assets/sass/variables';
@import 'assets/sass/mixins';
// class
@import 'assets/sass/override';
@import 'assets/sass/icons';
@import 'assets/sass/text';
@import 'assets/sass/layout';

/** ------------ */

body {
    // overflow-x: auto;
    // min-width: 1200px;
    background-color: $color-body;
}

.body-container {
    @include box-shadow(0px 0px 4px 4px);
    background-color: $color-white;
    border-top: 5px solid $color-cyan-tab-table;
    padding-bottom: 58px;
    padding: 21px 18px;
}

.breadcrumb {
    padding-top: 15px;
    padding-bottom: 15px;
    color: $color-grey-text-description;
}

.box {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    background-color: $color-white;
}

hr {
    border-top: 1px solid $color-line-light;
}

// Info
#body-container.no-bg {
    background-color: transparent !important;
    border-top: 0 !important;
    padding: 0 !important;
    box-shadow: 0 0 !important;
}

.order-info-box.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    a {
        pointer-events: none;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
        background-color: $color-white;
    }
}

// tax and vat
.order-summary-section.is-tax-vat {
    .tax-note,
    .vat-note,
    .vat-before {
        display: none !important;
    }
}

// group full / close
.join-program-order.is-closed,
.join-program-order.is-full {
    .order-table,
    .order-contact-seller > div,
    .order-commission,
    .order-summary,
    .order-period-commission,
    .order-period-commission-m,
    .order-period-promotion,
    .order-summary-section {
        opacity: 0.5;
        pointer-events: none;
    }
}

.order-booking-bar.is-closed,
.order-booking-bar.is-full {
    .order-booking-btn {
        color: $color-grey-light-100;
        background: $color-haze;
        border: 1px solid $color-haze;
        pointer-events: none;
    }
}
