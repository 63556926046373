// _text.scsss

.color-text-title {
    color: $color-grey-text-title;
}

.color-text-normal {
    color: $color-grey-text-normal;
}

.color-text-description {
    color: $color-grey-text-normal;
}

.color-grey-text-normal {
    color: $color-grey-text-normal;
}

.color-grey-text-description {
    color: $color-grey-text-description;
}

.color-white {
    color: $color-white;
}

.color-red-warning {
    color: $color-red-warning;
}

.color-blue {
    color: $color-blue;
}

.color-blue-tourwow {
    color: $color-blue-tourwow;
}

.color-cyan-tab-table {
    color: $color-cyan-tab-table;
}

.color-orange-text-note {
    color: $color-orange-text-note;
}

.color-red {
    color: $color-red-100;
}

.twp-text-xxxl-bold {
    @include twp-text-bold;
    @include font-xxxl;
}

.twp-text-xxl-bold {
    @include twp-text-bold;
    @include font-xxl;
}

.twp-text-xl-bold {
    @include twp-text-bold;
    @include font-xl;
}

.twp-text-l-bold {
    @include twp-text-bold;
    @include font-l;
}

.twp-text-m-bold {
    @include twp-text-bold;
    @include font-m;
}

.twp-text-s-bold {
    @include twp-text-bold;
    @include font-s;
}

.twp-text-xs-bold {
    @include twp-text-bold;
    @include font-xs;
}
.twp-text-xxxl {
    @include twp-text-regular;
    @include font-xxxl;
}

.twp-text-xxl {
    @include twp-text-regular;
    @include font-xxl;
}

.twp-text-xl {
    @include twp-text-regular;
    @include font-xl;
}

.twp-text-l {
    @include twp-text-regular;
    @include font-l;
}

.twp-text-m {
    @include twp-text-regular;
    @include font-m;
}

.twp-text-s {
    @include twp-text-regular;
    @include font-s;
}

.twp-text-xs {
    @include twp-text-regular;
    @include font-xs;
}
