// _icons.scss
.icon-fire {
    @include icon-twp;
    width: 12px;
    height: 12px;
    background-position: 0px -70px;
}

.icon-promotion {
    @include icon-promotion;
}

.icon-promotion-orange {
    @include icon-promotion-orange;
}

.icon-join-tour {
    @include icon-join-tour;
}

.icon-join-tour-orange {
    @include icon-join-tour-orange;
}

.icon-package-tour {
    // @include icon-twp;
    width: 20px;
    height: 20px;
    background-position: 20px 0px;
}

.icon-alarm {
    @include icon-twp;
    width: 12px;
    height: 12px;
    background-position: -17px -70px;
}

.icon-member {
    @include icon-header;
    background-position: 0px 0px;
}

.icon-member-modal {
    @include icon-modal;
    background-position: 0px 0px;
}

.icon-member-white {
    @include icon-menu;
    background-position: 0px 0px;
}

.icon-success-modal {
    @include icon-modal;
    background-position: -120px 0px;
}

.icon-alert-modal {
    @include icon-modal;
    background-position: -240px 0px;
}

.icon-info-modal {
    @include icon-modal;
    background-position: -360px 0px;
}

.icon-online-booking {
    @include icon-header;
    background-position: 0px -60px;
}

.icon-online-booking-white {
    @include icon-menu;
    background-position: 0px -60px;
}

.icon-order-history {
    @include icon-header;
    background-position: 0px -120px;
}

.icon-order-history-white {
    @include icon-menu;
    background-position: 0px -120px;
}

.icon-payment {
    @include icon-header;
    background-position: 0px -180px;
}

.icon-payment-white {
    @include icon-menu;
    background-position: 0px -180px;
}

.icon-passenger {
    @include icon-header;
    background-position: 0px -240px;
}

.icon-passenger-white {
    @include icon-menu;
    background-position: 0px -240px;
}

.icon-logout {
    @include icon-header;
    background-position: 0px -300px;
}

.icon-logout-white {
    @include icon-menu;
    background-position: 0px -300px;
}

.icon-cart {
    @include icon-header;
    background-position: 0px -300px;
}

.icon-twp-location {
    @include icon-twp;
    background-position-y: 75px;
}

.icon-twp-calendar {
    @include icon-twp;
    background-position-y: 45px;
}

.icon-twp-airplane {
    @include icon-twp;
    background-position-y: 15px;
}

.icon-twp-flight-go {
    @include icon-twp;
    width: 20px;
    background-position-y: 132px;
}

.icon-twp-flight-back {
    @include icon-twp;
    width: 20px;
    background-position-y: 105px;
}

.icon-twp-bell {
    @include icon-twp;
    background-position: -15px 160px;
}

.icon-order-status-pending-confirm {
    @include icon-order-status;
    background-position-y: 0px;
}

.icon-order-status-wating-payment {
    @include icon-order-status;
    background-position-y: -30px;
}

.icon-order-status-cancel {
    @include icon-order-status;
    background-position-y: 45px;
}

.icon-location {
    @include icon-location;
}

.icon-calendar {
    @include icon-calendar;
}

.icon-flight-go {
    @include icon-flight-go;
}

.icon-flight-return {
    @include icon-flight-return;
}

.icon-word-file {
    @include icon-word-file;
}

.icon-pdf-file {
    @include icon-pdf-file;
}

.icon-img-file {
    @include icon-img-file;
}

.icon-urgent {
    @include icon-urgent;
}

.icon-urgent-red {
    @include icon-urgent-red;
}

.icon-expand {
    @include icon-expand;
}

.icon-back {
    @include icon-back;
}

.icon-back-big {
    @include icon-back;
    width: 35px;
    height: 26px;
    background-repeat: no-repeat;
    background-size: contain;
}

@mixin icon-price {
    width: 100%;
    background-size: cover !important;
    height: 0;
    padding: 0px;
    display: inline-block;
}

.icon-price-1 {
    // price_adult_double
    @include icon-price();
    padding-bottom: 40%;
    background: url('/assets/icons/price_adult_double.svg');
}

.icon-price-2 {
    // price_adult_single
    @include icon-price();
    padding-bottom: 85%;
    background: url('/assets/icons/price_adult_single.svg');
}

.icon-price-3 {
    // price_adult_triple
    @include icon-price();
    padding-bottom: 25%;
    background: url('/assets/icons/price_adult_triple.svg');
}

.icon-price-4 {
    // price_child_bed
    background: url('/assets/icons/price_child_bed.png');
    background-size: cover;
    width: 26px;
    height: 11px;
    display: inline-block;
}

.icon-price-5 {
    // price_child_no_bed
    background: url('/assets/icons/price_child_no_bed.png');
    background-size: cover;
    width: 11px;
    height: 11px;
    display: inline-block;
}

.icon-price-6 {
    // price_infant
    background: url('/assets/icons/price_infant.png');
    background-size: cover;
    width: 7px;
    height: 9px;
    display: inline-block;
}

.icon-price-7,
.icon-price-9 {
    // price_join_land
    background: url('/assets/icons/price_join_land.svg');
    width: 30px;
    height: 30px;
    display: inline-block;
}

.icon-white-order_status-pending_approve_order {
    @include icon-white-order_status;
    background-position-x: 0px;
    background-position-y: 0px;
}
.icon-white-order_status-waiting_paid {
    @include icon-white-order_status;
    background-position-x: 0px;
    background-position-y: -79px;
}
.icon-white-order_status-pending_completed {
    @include icon-white-order_status;
    background-position-x: 0px;
    background-position-y: -148px;
}
.icon-white-order_status-completed {
    @include icon-white-order_status;
    background-position-x: 0px;
    background-position-y: -219px;
}
.icon-white-order_status-canceled {
    background-image: url('/assets/icons/icon-cancel.svg');
    background-repeat: no-repeat;
    width: 33px;
    height: 31px;
    display: inline-block;
    vertical-align: middle;
    background-position-x: 0px;
    background-position-y: 0px;
}
.icon-check {
    background-image: url('/assets/icons/icons-check.png');
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
    background-position-x: 0px;
    background-position-y: 0px;
}
.icon-deal {
    @include icon-deal;
}

.icon-verify {
    background-image: url('/assets/icons/icon-verify.png');
    background-repeat: no-repeat;
    background-size: 40px;
    width: 40px;
    height: 20px;
}
.icon-arrow-down {
    background-image: url('/assets/icons/icon-arrow-blue.svg');
    background-repeat: no-repeat;
    background-size: 15px;
    width: 15px;
    height: 10px;
    display: inline-flex;
}
.icon-arrow-up {
    background-image: url('/assets/icons/icon-arrow-blue.svg');
    background-repeat: no-repeat;
    background-size: 15px;
    width: 15px;
    height: 10px;
    display: inline-flex;
    transform: rotate(180deg);
}
.icon-clip {
    background-image: url('/assets/icons/icon-clip.png');
    background-repeat: no-repeat;
    background-size: 15px;
    width: 15px;
    height: 25px;
}
.icon-plus-circle {
    background-image: url('/assets/icons/icon-plus-circle.svg');
    background-repeat: no-repeat;
    background-size: 13px;
    width: 15px;
    height: 15px;
}
.icon-check2 {
    background-image: url('/assets/icons/icons-check.png');
    background-repeat: no-repeat;
    background-size: 15px;
    width: 15px;
    height: 15px;
}
.icon-pending {
    background-image: url('/assets/icons/icon-pending-black.svg');
    background-repeat: no-repeat;
    background-size: 15px;
    width: 15px;
    height: 15px;
}
.icon-cancel {
    background-image: url('/assets/icons/icon-cancel.svg');
    background-repeat: no-repeat;
    background-size: 15px;
    width: 15px;
    height: 15px;
}
.icon-membership {
    background-image: url('/assets/icons/icon-membership.svg');
    background-repeat: no-repeat;
    background-size: 15px;
    width: 15px;
    height: 15px;
}
