// _override.scss Override class bootstrap 4
@import 'assets/sass/_variables.scss';
// global
body,
input,
textarea,
select {
    // font-family: Thonburi;
    color: $color-grey-text-normal;
}

p,
span,
label,
li {
    @include font-m;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

// wrapper
.container-fluid {
    @include web-container;
}

.section {
    margin-bottom: 15px;
}

.btn-wrapper {
    margin-bottom: 30px;
    .btn {
        margin-left: 5px;
        margin-right: 5px;
        padding-left: 50px;
        padding-right: 50px;
    }
}

// modal
.modal {
    &-dialog {
        min-height: calc(100vh - 60px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: auto;
        @media (max-width: 768px) {
            min-height: calc(100vh - 20px);
        }
    }
    &-header {
        border: 0px;
        button.close.pull-right {
            span {
                font-size: 20px;
            }
        }
    }
    &-footer {
        justify-content: center;
    }
}

// form
.form-control {
    @include font-m;
}

.form-control.is-invalid {
    background-image: unset;
}

select.form-control {
    @include twp-text-bold;
    @include font-xl;
    color: $color-grey-text-title;
}

textarea.form-control {
    min-height: 80px;
    resize: none;
}

input[type='checkbox'] {
    & ~ .custom-control-label {
        line-height: 25px;
        &::after {
            width: 25px;
            height: 25px;
            top: 0px;
            left: -28px;
        }
    }
}

// button
.btn {
    @include font-l;
    color: $color-btn-text;
    &-primary,
    &-primary:hover {
        color: $color-white;
        background-color: $color-blue;
        border-color: $color-blue;
    }
    &-default,
    &-default:hover {
        color: $color-blue-tourwow;
        background-color: $color-white;
        border-color: $color-blue-tourwow;
    }
}

.text-body {
    color: $color-grey-text-normal !important;
}

// text
.text-link {
    @include twp-text-regular;
    @include font-m;
    color: $color-grey-text-title;
    text-decoration: underline;
}

// title
.title {
    &-main {
        @include twp-text-bold;
        @include font-xxl;
        color: $color-blue-tourwow;
        display: inline-flex;
        align-items: center;
        margin-bottom: 20px;
        i {
            margin-right: 10px;
        }
    }
    &-section {
        @include twp-text-bold;
        @include font-xl;
        color: $color-grey-text-title;
    }
    &-content {
        @include twp-text-bold;
        @include font-l;
        color: $color-grey-text-normal;
    }
}

.btn-primary,
.btn-primary:hover {
    color: $color-white;
    background-color: $color-blue-tourwow;
    border-color: $color-blue-tourwow;
}

// tabs
.nav {
    &.nav-tabs {
        font-size: 14px !important;
        border-bottom: 2px solid $color-orange-text-note;
        overflow: hidden;
        display: inline-flex;
        justify-content: flex-start;
        width: 100%;
    }
    .nav-item {
        background-color: $color-white !important;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15) !important;
        margin-right: 10px;
        line-height: 28px;
        border: 0;
        border-radius: 3px 3px 0 0;
        min-width: 100px;
        text-align: center;
        &:hover {
            background-color: none !important;
        }
    }
    .nav-item.active {
        background-color: $color-orange-text-note !important;
    }
    .nav-link {
        background: none;
        border: 0;
        color: $color-grey-text-normal;
        // &:hover {
        // background-color: $color-orange-text-note  !important;
        // color: $color-white;
        // img {
        //     filter: brightness(0) invert(1);
        // }
        // }
        &.active {
            background-color: $color-orange-text-note !important;
            color: $color-white;
            img {
                filter: brightness(0) invert(1);
            }
        }
    }
}

@media (max-width: 992px) {
    .nav {
        &.nav-tabs {
            justify-content: space-between;
            width: 100%;
            .nav-item {
                flex-grow: 1;
                min-width: inherit !important;
                &:last-child {
                    margin: 0;
                }
            }
            .nav-link {
                padding: 7px 0;
            }
        }
    }
}

@media (max-width: 576px) {
    .nav {
        .nav-item {
            margin-right: 3px !important;
        }
        .nav-link {
            span {
                font-size: 10px !important;
            }
        }
    }
    .nav li:first-child a {
        margin-top: 15px;
    }
    .nav li:first-child a span {
        font-size: 12px !important;
    }
}

.tab-content {
    > .active {
        margin-top: 20px;
    }
}

.form-control {
    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $color-placeholder;
    }
    &::-moz-placeholder {
        /* Firefox 19+ */
        color: $color-placeholder;
    }
    &:-ms-input-placeholder {
        /* IE 10+ */
        color: $color-placeholder;
    }
    &:-moz-placeholder {
        /* Firefox 18- */
        color: $color-placeholder;
    }
}

// checkbox
.checkbox {
    position: relative;
    padding: 0 0 0 20px;
    line-height: 18px !important;
    cursor: pointer;
    color: #687d94;
}

.checkbox > input:checked ~ .icon {
    background-color: $color-blue-tourwow;
    color: $color-white;
    border-color: $color-blue-tourwow;
}

.checkbox .icon {
    border-radius: 3px;
    background: #fff;
    border: 1px solid #cfd6df;
    color: #fff;
    display: inline-block;
    vertical-align: top;
    width: 15px;
    height: 15px;
    line-height: 14px;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
}

.fa-warning {
    color: #ffab04;
}

.cursor-pointer {
    cursor: pointer;
}

.modal-dialog.modal-generic {
    width: 400px;
}

.modal.modal-generic-mobile {
    top: 58px;

    .modal-dialog.modal-generic {
        margin: 0;
        width: 100%;
        max-width: unset;
        height: 100%;
        border: 0;
        border-radius: 0;

        .modal-content {
            height: auto;
            min-height: 100%;
            border: 0;
            border-radius: 0;

            & > * {
                max-height: calc(100vh - 58px);
                overflow-y: scroll;
            }
        }
    }
}

.modal-border-radius-10 > .modal-content {
    border-radius: 10px;
}
