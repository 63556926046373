// _variables.scss
$color-white: #ffffff;
$color-black: #000000;
$color-grey-button-disable: #f1f4f9;
$color-grey-text-disable: #b8c7d7;
$color-grey-text-title: #344251;
$color-grey-text-normal: #56697e;
$color-grey-text-description: #8395a8;
$color-green-avaliable: #289f77;
$color-blue: #0a7099;
$color-blue-btn-search: #196e9a;
$color-blue-tourwow-pro: #155169;
$color-blue-tourwow: #146d99;
$color-cyan-tab-table: #19a9c3;
$color-red-warning: #e12d2d;
$color-orange-text-note: #ff6704;
$color-btn-text: #fafbfd;
$color-blue-table-tr: #e7f4f8;
$color-line: #eceef3;
$color-line-light: #d8d8d8;
$color-body: #eceef3;
//
$color-blue-40: #96b1bc;
$color-blue-25: #b5cddc;
$color-grey-light: #8a9bad;
$color-grey-light-50: #eceef3;
$color-grey-light-100: #8a9bad;
$color-green-100: #01c596;
$color-red-100: #ff010a;
$color-haze: #e0e7ee;
$color-limegreen: #3bb54a;
$color-placeholder: #d1dae4;
