@import 'assets/sass/_variables.scss';
@import 'assets/sass/_mixins.scss';

body {
    margin: 0;
    padding: 0;
    background-color: $color-body;
}

#wrapper {
    transition: all 0.5s;
}

.expanded {
    margin-left: 175px !important;
}

.set-password-container {
    margin-top: 40px;

    .form-container {
        margin-top: auto;
        padding-bottom: 20px;
        .form-group {
            margin-bottom: 0.7rem;
        }
        .col-form-label {
            font-size: 12px;
        }
        .btn-wrapper {
            .btn {
                width: 247px;
            }
        }
    }
}
