// _mixins.scss
@font-face {
    font-family: Thonburi;
    src: url('/assets/fonts/Thonburi.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Thonburi;
    src: url('/assets/fonts/Thonburi-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

/**
    Text Style Class
*/

@mixin font-xxxl {
    font-size: 24px;
}

@mixin font-xxl {
    font-size: 18px;
    line-height: 22px;
}

@mixin font-xl {
    font-size: 16px;
    line-height: 18px;
}

@mixin font-l {
    font-size: 14px;
}

@mixin font-m {
    font-size: 12px;
    line-height: 15px;
}

@mixin font-s {
    font-size: 10px;
}

@mixin font-xs {
    font-size: 8px;
    line-height: 12px;
}

@mixin twp-text-bold {
    // font-family: Thonburi;
    font-weight: bold;
}

@mixin twp-text-regular {
    // font-family: Thonburi;
}

@mixin icon-header {
    background: url('/assets/images/icons-header.png');
    width: 30px;
    height: 30px;
    display: inline-block;
}

@mixin icon-menu {
    background: url('/assets/images/icons-menu.png');
    width: 30px;
    height: 30px;
    display: inline-block;
}

@mixin icon-twp {
    background: url('/assets/images/icons-twp.png');
    display: inline-block;
}

@mixin icon-modal {
    background: url('/assets/images/icons-modal.png');
    width: 60px;
    height: 60px;
    display: inline-block;
}

@mixin icon-order-status {
    background: url('/assets/images/icons-orderstatus.png');
    width: 15px;
    height: 15px;
    display: inline-block;
}

@mixin icon-location {
    background: url('/assets/icons/ico-location.svg');
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    display: inline-block;
}

@mixin icon-calendar {
    background: url('/assets/icons/ico-calendar.svg');
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    display: inline-block;
}

@mixin icon-flight-go {
    background: url('/assets/icons/ico-flight-go.svg');
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    display: inline-block;
}

@mixin icon-flight-return {
    background: url('/assets/icons/ico-flight-return.svg');
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    display: inline-block;
}

@mixin icon-word-file {
    background: url('/assets/icons/ico-word-file.svg');
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    display: inline-block;
}

@mixin icon-pdf-file {
    background: url('/assets/icons/ico-pdf-file.svg');
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    display: inline-block;
}

@mixin icon-img-file {
    background: url('/assets/icons/ico-img-file.svg');
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    display: inline-block;
}

@mixin icon-urgent {
    background: url('/assets/icons/ico-urgent.svg');
    min-width: 18px;
    height: 16px;
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: 5px;
}

@mixin icon-urgent-red {
    background: url('/assets/icons/ico-urgent-red.svg');
    min-width: 18px;
    height: 16px;
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: 5px;
}

@mixin icon-expand {
    background: url('/assets/icons/icon-expand.png');
    width: 15px;
    height: 10px;
    background-repeat: no-repeat;
    display: inline-block;
}

@mixin icon-twp {
    background: url('/assets/images/icons-twp.png');
    width: 15px;
    height: 15px;
    display: inline-block;
}

@mixin icon-promotion {
    background: url('/assets/icons/ico-promotion.svg');
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: inline-block;
}

@mixin icon-promotion-orange {
    background: url('/assets/icons/ico-promotion-orange.svg');
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: inline-block;
}

@mixin icon-join-tour {
    background: url('/assets/icons/ico-join-tour.svg');
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: inline-block;
}

@mixin icon-join-tour-orange {
    background: url('/assets/icons/ico_join_tour_orange.svg');
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: inline-block;
}

// @mixin icon-price {
//     background: url('/assets/icons/icon-price.png');
//     width: 27px;
// 	height: 22px;
//     display: inline-block;
// }
@mixin icon-back {
    background: url('/assets/icons/ico-previous.svg');
    width: 18px;
    height: 13px;
    display: inline-block;
}

@mixin icon-white-order_status {
    background-image: url('/assets/icons/icon-order-status-white.png');
    background-repeat: no-repeat;
    width: 33px;
    height: 31px;
    display: inline-block;
    vertical-align: middle;
}

@mixin icon-deal {
    background: url('/assets/icons/ico-deal.svg');
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: 5px;
}

@mixin box-shadow($border-radius) {
    border-radius: $border-radius;
    -moz-border-radius: $border-radius;
    -webkit-border-radius: $border-radius;
    box-shadow: 2px 4px 4px 0 rgba(162, 162, 162, 0.5);
}

@mixin web-container {
    max-width: 1120px;
    margin: auto;
}

@mixin flex-align-center {
    display: flex;
    align-items: center;
}

@mixin flex-align-justify-center {
    @include flex-align-center;
    justify-content: center;
}

@mixin inline-flex-align-center {
    display: inline-flex;
    align-items: center;
}

@mixin inline-flex-align-justify-center {
    @include inline-flex-align-center;
    justify-content: center;
}

@mixin pointer {
    cursor: pointer;
}
